  <template>
    <div id="termos-de-uso">
      <funcionalidade-header
        :voltar="false"
        titulo="Termos de uso">
      </funcionalidade-header>
      <div  id="scrollbar-none" class="termos-content">
        <div class="header-termos">
          <h1 class="titulo-termos">{{termoDeUso.titulo}}</h1>
          <small class="versao-termo">Versão {{termoDeUso.versao}} - atualizado em {{dataTermo}}</small>
          <small v-if="dataAceite" class="versao-termo">Termo aceito em {{dataAceito}}</small>
        </div>
        <div class="border-dashed"></div>
        <div class="termos-text" v-html="termoDeUso.conteudo_termo_uso"></div>
      </div>
      <el-footer v-if="buttonsFooter" class="footer-termos">
        <div class="button-options">
          <el-button class="button-primary" @click="aceitarTermo()" type="primary">DECLARO QUE LI E ACEITO</el-button>
          <el-button class="button-text" @click="$router.push({ path: '/'})" type="text">NÃO ACEITO</el-button>
        </div>
      </el-footer>
    </div>
  </template>
<script>
import FuncionalidadeHeader from '@/components/FuncionalidadeHeader/FuncionalidadeHeader'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'TermosDeUso',
  data () {
    return {
      termoDeUso: '',
      dataAceite: ''
    }
  },
  props: {
    idTermo: {
      type: [Number, String],
      default: 1
    },
    buttonsFooter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FuncionalidadeHeader
  },
  computed: {
    ...mapGetters('SetupApp', ['aplicacao']),
    ...mapState('SetupApp', ['apiFuncionalidade', 'apiCMS']),
    dataTermo () {
      let data = moment(this.termoDeUso.data_publicacao).format('DD/MM/YYYY')
      return data
    },
    dataAceito () {
      let data = moment(this.dataAceite).format('DD/MM/YYYY')
      return data
    }
  },
  methods: {
    async getTermosDeUso () {
      const result = await this.apiCMS.termosUsoUltimoValido({
        payload: {
          'id_termo_uso_plat': this.idTermo
        }
      })
      this.termoDeUso = result.data
    },
    async aceitarTermo () {
      let res = await this.apiCMS.termosUsoConfirmarAceite({
        payload: {
          'id_termo_uso_plat': this.idTermo,
          'aceito': true,
          'versao': this.termoDeUso.versao,
          'id_termo_uso': this.termoDeUso.id_termo_uso
        }
      })
      if (res.data.aceito) {
        this.$router.go(-1)
      }
    }
  },
  created () {
    this.getTermosDeUso()
  }
}
</script>
  <style>
    .termos-content {
      padding-left: 20px;
      max-width: 609px;
    }
    .header-termos {
      margin-bottom: 30px;
    }
    .border-dashed {
      border-bottom: dashed 1px #EEEEEE;
      margin-bottom: 30px;
    }
    .titulo-termos {
      margin-top: 20px;
      font-family: 'Noto', Serif;
      font-size: 36px;
    }
    .versao-termo {
      display: block;
      font-family: "Open Sans";
      font-weight: 600;
      margin-top: 20px;
      color: #555555;
    }
    .footer-termos {
      box-shadow: 0px 2px 8px #0001011A;
      height: 64px;
    }
    #scrollbar-none::-webkit-scrollbar {
      width: 0px;
    }
    .button-options {
      padding-top: 15px;
    }
    .button-text {
      border: 1px solid #006FB9;
      color: #006FB9;
      width: 295px;
      height: 34px;
    }
    .button-primary {
      width: 295px;
      height: 34px;
    }
  </style>
