import mshubPayload from '@/utils/mshubPayload'
import store from '@/Store'

const urlBase = process.env.VUE_APP_PLATAFORMA_BENEFICIARIO_API
const PlataformaBeneficiarioApi = (API) => {
  return {
    async carregar () {
      const url = `${urlBase}/beneficiario/me`
      return API.post(url, mshubPayload({}), {
        headers: {
          instancia_aplicacao: store.getters['SetupApp/instanciaAplicacao']
        }
      })
    }
  }
}

export default PlataformaBeneficiarioApi
