<template>
    <div v-html="htmlInstanciaChat"/>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { ConfiguradorResource } from '@/API'

export default {
  name: 'ChatMosiaInstancia',
  props: {
    idFuncionalidade: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      funcionalidadeEmbed: null,
      funcionalidade: null,
      htmlInstanciaChat: ''
    }
  },
  computed: {
    ...mapGetters('SetupApp', ['bundle', 'aplicacao']),
    ...mapState('SetupApp', ['instanciaAplicacao']),
    ...mapGetters('Login', ['token']),
    ...mapGetters('Beneficiario', ['user']),
    tokenChat () {
      return this.funcionalidade.parametros.tokenInstanciaChat
    }
  },
  methods: {
    async init () {
      this.funcionalidade = await ConfiguradorResource.getSetupFuncionalidade({
        idFuncionalidade: this.idFuncionalidade,
        bundle: this.bundle,
        instanciaAplicacao: this.instanciaAplicacao
      })
      let html = `<div
                    class="mcf-widget"
                    data-mcf-token="${this.tokenChat}"
                    data-mcf-id-funcionalidade="${this.idFuncionalidade}"
                    data-mcf-bundle="${this.bundle}"
                    data-mcf-instancia-aplicacao="${this.instanciaAplicacao}"
                    data-mcf-userid="${this.montaDadosUser()}"
                    data-mcf-token-contato="${this.token}"
                    ></div>`
      this.htmlInstanciaChat = html
    },
    montaDadosUser () {
      let payload = {
        contato: {
          chaveUnica: this.user.chaveUnica
        },
        alocacao: {
          codigoAgente: null,
          codigoFila: null,
          codigoPerfil: null
        }
      }
      return btoa(JSON.stringify(payload))
    },
    scriptTagCreator () {
      const script = document.createElement('script')
      const id = 'mcf-jssdk'
      if (document.getElementById(id)) {
        return
      }
      script.src = process.env.VUE_APP_SDK_CHAT
      script.id = id
      document.body.appendChild(script)
    },
    destroyScriptTag () {
      const script = document.getElementById('mcf-jssdk')
      if (script) {
        script.remove()
      }
    }
  },
  async mounted () {
    await this.init()
    this.scriptTagCreator()
  },
  beforeDestroy () {
    this.destroyScriptTag()
  }
}
</script>

<style lang="less" scoped>
.floating-chat {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 19px;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  width: 300px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  #chat {
    width: 100%;
    height: 600px;
  }
}
</style>
