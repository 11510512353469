const urlBase = process.env.VUE_APP_IMAGE_BUCKET
const urlClient = `${urlBase}/plataforma-beneficiario/clientes`
const urlIcones = `${urlBase}/icones`
const urlIconesDash = `${urlBase}/icones_dash`

export const loginBackgroundBanner = (bundle) => {
  return `${urlClient}/${bundle}/benefeciario_fundo.jpg`
}

export const clienteLogo = (bundle) => {
  return `${urlClient}/${bundle}/logo_color.png`
}

export const mobileSaudeLogo = () => {
  return `img/footer_logo_mobile.png`
}

export const urlIcons = (icone) => {
  if (icone.toLowerCase().indexOf('dash_') === 0) {
    return `${urlIconesDash}/${icone}.svg`
  }
  return `${urlIcones}/${icone}.svg`
}

export const errorVazio = () => {
  return urlIcons('img_helper_empty_default')
}

export const errorAlerta = () => {
  return urlIcons('img_helper_error')
}

export const errorCarregar = () => {
  return urlIcons('img_helper_cloud')
}

export const errorInternet = () => {
  return urlIcons('img_helper_wifi')
}

export const favicon = (bundle) => {
  return `${urlClient}/${bundle}/favicon.ico`
}

export const faviconComum = () => {
  return `${urlIcones}/favicon.ico`
}
