<template>
  <span class="container-ans" @click="openLink">
    <span>
      ANS - Nº {{ config.aplicacao.numeroAns }}
    </span>
  </span>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AnsButton',
  data () {
    return {
    }
  },
  computed: {
    ...mapState('SetupApp', ['config'])
  },
  methods: {
    openLink () {
      if (!this.config.aplicacao.urlSiteANS) return
      window.open(this.config.aplicacao.urlSiteANS, '_blank')
    }
  }
}
</script>

<style scoped>
.container-ans{
  background-color: #000;
  padding: 3px 2px 4px 2px;
  cursor: hand;
}
.container-ans span{
  font-size: 12px;
  color: #FFF;
  border: 1px solid #FFF;
  padding: 2px 4px 1px 2px;
}
</style>
