import Bowser from 'bowser'
import store from '@/Store'
import canal from '@/utils/canal'

const getVersao = () => {
  return `${process.env.NODE_ENV.slice(0, 3).toUpperCase()}-${process.env.VUE_APP_VERSAO_BUILD}`
}

const mshubPayload = (payload) => {
  const config = store.state.SetupApp.config
  const userAgentInfos = Bowser.parse(window.navigator.userAgent)

  return {
    negocio: { ...payload }, // payload
    sistema: {
      app: {
        bundle: config.aplicacao.bundle,
        canal: canal,
        versao: getVersao(),
        versaoConfigurador: config.aplicacao.versao,
        sandbox: `${store.getters['SetupApp/instanciaAplicacao']}` === '1',
        instancia_aplicacao: store.getters['SetupApp/instanciaAplicacao']
      },
      dispositivo: {
        plataforma: userAgentInfos.os.name,
        versaoPlataforma: userAgentInfos.os.version,
        navegador: userAgentInfos.browser.name,
        versaoNavegador: userAgentInfos.browser.version
      },
      localidade: {
        latitude: undefined,
        longitude: undefined
      },
      token: {}
    }
  }
}

export default mshubPayload
