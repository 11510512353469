<template>
  <div class="side-menu">
    <div class="side-menu-content">
      <div class="side-menu-banner">
        <el-image
            style="height: 70px;"
            :src="clienteLogo"
            class="side-menu-logo"
            fit="contain"
        />
      </div>
      <div class="side-menu-buttons">
        <el-button
            type="text"
            class="side-menu-button"
            v-for="(item, index) in tabBar.filter((i) => i.id !== '47999')"
            :style="'--cor-primaria:' + background"
            :class="{ active: item.id === tabSelecionada.id }"
            :key="index"
            @click="open(item, index)"
        >
          <svgIcone
              v-if="tabSelecionada.id !== item.id"
              :cor=" estilo.aplicacao.corPrimariaAplicacao"
              :icone="item.icone"
              :class="{ active: item.id === tabSelecionada.id }"
              :height="22"
          />
          <svgIcone
              v-if="item.id === tabSelecionada.id"
              :cor="'#FFFFFF'"
              :icone="item.icone"
              :class="{ active: item.id === tabSelecionada.id }"
              :height="22"
          />
          <span v-if="tabSelecionada.id !== item.id" class="side-menu-item-text" :style="'color:' + estilo.aplicacao.corPrimariaAplicacao">{{item.nome }}</span>
          <span v-if="tabSelecionada.id === item.id" class="side-menu-item-text" :style="'color:' + '#FFFFFF'">{{item.nome }}</span>
        </el-button>
      </div>
      <div style="height: 20px;">
        <AnsButton/>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { isNotAccessGranted, slugGenerator } from '@/utils/utils'
import AnsButton from '@/components/AnsButton/AnsButton.vue'

export default {
  name: 'SideMenu',
  components: { AnsButton },
  data () {
    return {
      itemActive: 0
    }
  },
  props: ['tabSelecionada'],
  computed: {
    ...mapState('SetupApp', ['config']),
    ...mapGetters('SetupApp', ['tabBar', 'bundle', 'estilo', 'imagens']),
    ...mapGetters('Beneficiario', ['user']),
    clienteLogo () {
      return this.imagens.logoClienteWeb
    },
    background () {
      if (this.estilo && this.estilo.aplicacao && this.estilo.aplicacao.corPrimariaAplicacao) {
        return this.estilo.aplicacao.corPrimariaAplicacao
      }

      return ''
    }
  },
  methods: {
    async open (item, index) {
      if (await isNotAccessGranted(item.id.toString())) {
        let permissao = this.user.permissoes.filter(permissao => permissao.funcionalidade === item.id.toString()) || []
        if (permissao.length > 0) {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: permissao[0].mensagemBloqueio || 'Funcionalidade indisponível para o usuário.'
          })
        } else {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: 'Funcionalidade indisponível para o usuário.'
          })
        }
        return
      }
      const path = slugGenerator(item.nome)
      this.$router.push(`/dashboard/${path}`)
    }
  }
}
</script>
<style
    scoped
>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 277px;
  background-color: #F6F6F6;
  background-image: url("https://omni-public-assets.s3.sa-east-1.amazonaws.com/portalBeneficiario/testing/comuns/sombra-interna-right.svg");
  background-position: right;
  background-repeat: repeat-y;
  padding: 40px 20px 20px;
}

.side-menu-content {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.side-menu-banner {
  display: flex;
  flex-flow: column;
  margin-bottom: 40px;
}

.side-menu-logo {
  width: 203px;
  height: 40px;
}

.side-menu-title {
  text-align: left;
  font-size: 11px;
  letter-spacing: 0;
  color: #333333;
  text-transform: uppercase;
  padding-left: 45px;
  font-weight: 700;
  margin-top: 6px;
}

.side-menu-buttons {
  flex: 1;
}

.side-menu-button {
  width: 100%;
  margin-bottom: 15px;
  text-align: left;
  margin-left: 0;
  font-size: 13px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.side-menu-item-text {
  position: absolute;
  margin-top: 6px;
  margin-left: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
.active {
  background-color: var(--cor-primaria);
  color: #FFFFFF;
  border-radius: 30px;
}
</style>
