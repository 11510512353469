// Vee Validate
// Simple Vue.js input validation plugin https://baianat.github.io/vee-validate/
// https://github.com/baianat/vee-validate
import VeeValidate from 'vee-validate'
import VeeValidateDictionary from './dictionary'

const plugin = {
  install: Vue => {
    Vue.use(VeeValidate, {
      dictionary: VeeValidateDictionary,
      errorBagName: 'errors', // change if property conflicts
      events: 'input',
      fieldsBagName: 'fieldsVeeValidate',
      locale: 'pt_BR'
    })
  }
}

export default plugin
