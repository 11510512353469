const publicRoutes = [
  '/pesquisa/',
  '/descredenciados'
]

export const isPublicRoute = (path) => {
  const regexPublicRoutes = new RegExp('^' + publicRoutes.join('|^'))
  if (!path.match(regexPublicRoutes)) {
    return false
  }

  return true
}
