// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Vuex
// Centralized State Management for Vue.js.
// https://github.com/vuejs/vuex
import Vuex from 'vuex'

// Modules
// https://vuex.vuejs.org/guide/modules.html
import Login from '@/store/modules/Login'
import SetupApp from '@/store/modules/SetupApp'
import Beneficiario from '@/store/modules/Beneficiario'

// Vuex Persistedstate
// Persist and rehydrate your Vuex state between page reloads.
// https://github.com/robinvdvleuten/vuex-persistedstate
import createPersistedState from 'vuex-persistedstate'

// Vuex Persistedstate
// Persist and rehydrate your Vuex state between page reloads.
// https://github.com/robinvdvleuten/vuex-persistedstate
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// Making sure that we're doing everything correctly by enabling
// strict mode in the dev environment.
// https://vuex.vuejs.org/guide/strict.html
const debug = process.env.NODE_ENV !== 'production'

// root state
const state = {
}

// root getters
const getters = {
}

export default new Vuex.Store({
  state,
  getters,
  modules: {
    Beneficiario,
    Login,
    SetupApp
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: 'key-plataforma-beneficiario-web',
      paths: ['Login', 'SetupApp']
    })
  ]
})
