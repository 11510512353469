<template>
  <el-button
    :style="`background-color: ${corIcone}14`"
    class="lista-servicos-button"
    round
    style="border-radius: 10px !important; padding-right: 5px !important; padding-left: 15px !important;"
    @click="$emit('click', funcionalidade)"
    v-if="isNotHidden(funcionalidade.id)"
  >
    <div style="display:flex; flex-flow: row nowrap; align-items: center;">
      <div style="display:flex; flex-flow: column nowrap; row-gap: 16px; flex: 1;">
        <svgIcone
          :height="22"
          :cor="corIcone"
          :icone="funcionalidade.icone"
        />
        <span
          class="btn-text"
          :style="`color: ${corTexto}`"
        >
          {{ funcionalidade.nome }}
        </span>
      </div>

      <div
        v-if="funcionalidade.tipo === 'grupo'"
        :style="`background-color: ${estilo.aplicacao.corPrimariaAplicacao}`"
        style="height: 26px; width: 1px;"
      />
    </div>
  </el-button>
</template>
<script>
import { mapGetters } from 'vuex'
import { idFuncionalidadesOcultas } from '@/utils/utils'

export default {
  name: 'FuncionalidadeBtn',
  props: {
    funcionalidade: {},
    index: {}
  },
  computed: {
    ...mapGetters('SetupApp', ['estilo', 'dashBoard']),
    corIcone () {
      let color
      this.funcionalidade.corIcone ? color = this.funcionalidade.corIcone : color = this.estilo.aplicacao.corPrimariaAplicacao
      return color
    },
    corTexto () {
      let color
      this.funcionalidade.corLabel ? color = this.funcionalidade.corLabel : color = ''
      return color
    }
  },
  methods: {
    isNotHidden (funcionalidade) {
      let funcionalidadesOcultas = idFuncionalidadesOcultas()
      return !funcionalidadesOcultas.includes(funcionalidade.toString())
    }
  }
}
</script>
<style scoped>
.lista-servicos-button {
  width: 100%;
  height: 86px;
  text-align: left;
  margin-left: 0;
  border: none;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
}
.btn-text {
  font-family: 'Open Sans', sans-serif;
}
</style>
