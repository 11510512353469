<template>
  <svg
    height="150"
    viewBox="0 0 220 150"
    width="220"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="a">
        <circle
          class="a"
          cx="59.2"
          cy="59.2"
          r="59.2"
        />
      </clipPath>
    </defs>
    <rect
      class="b"
      height="150"
      width="220"
    />
    <g transform="translate(-180.38 -399)">
      <circle
        class="c"
        cx="60"
        cy="60"
        r="60"
        transform="translate(230.38 429)"
      />
      <g
        class="d"
        transform="translate(231.18 429.8)"
      >
        <path
          class="a"
          d="M181.654,227.074c0-4.279-3.723-9.88-4.509-6.221a5.569,5.569,0,0,0-2.812-.734,5.906,5.906,0,0,0-1.281.14,4.662,4.662,0,0,0-4.556-2.76,5.7,5.7,0,0,0-2.33.486,6.168,6.168,0,0,0-5.156-2.454c-2.666,0-4.912,1.411-5.6,3.336a7.938,7.938,0,0,0-4.554-1.368,8.023,8.023,0,0,0-4.291,1.193q.01-.144.01-.29c0-2.977-3.064-5.39-6.843-5.39a8.227,8.227,0,0,0-3.567.79c-1.775-3.017-5.651-5.111-10.15-5.111-5.951,0-10.815,3.663-11.161,8.282-3.9.524-6.867,3.2-6.867,6.417,0,3.6,3.7,6.512,8.267,6.512h71.383v-.027C192.694,229.556,181.654,231.142,181.654,227.074Z"
          transform="translate(-105.029 -166.866)"
        />
        <path
          class="a"
          d="M430.723,184.948a1.668,1.668,0,0,0,.08-.5,2.415,2.415,0,0,0-2.644-2.083,3.325,3.325,0,0,0-.419.029c-.1-1.465-1.643-2.629-3.529-2.629a3.929,3.929,0,0,0-2.786,1.073,4.266,4.266,0,0,0-4.1-2.417,4.358,4.358,0,0,0-4.01,2.2,3.161,3.161,0,0,0-1.436-.335,2.415,2.415,0,0,0-2.644,2.083c-2.362,0-4.276,1.508-4.276,3.368,0,1.786,1.764,3.246,3.995,3.36v.008h21.492a2.414,2.414,0,0,0,2.644-2.083A2.344,2.344,0,0,0,430.723,184.948Z"
          transform="translate(-275.119 -149.535)"
        />
        <path
          class="a"
          d="M283.308,330.026a2.966,2.966,0,0,0,.142-.888c0-2.045-2.1-3.7-4.7-3.7a5.951,5.951,0,0,0-.746.051c-.183-2.6-2.921-4.672-6.274-4.672a6.984,6.984,0,0,0-4.953,1.906c-.927-2.483-3.84-4.3-7.292-4.3-3.268,0-6.054,1.625-7.128,3.9a5.624,5.624,0,0,0-2.553-.6c-2.6,0-4.7,1.658-4.7,3.7-4.2,0-7.6,2.68-7.6,5.987,0,3.174,3.136,5.77,7.1,5.973v.015h38.205c2.6,0,4.7-1.658,4.7-3.7C287.509,331.8,285.669,330.222,283.308,330.026Z"
          transform="translate(-179.211 -229.696)"
        />
        <path
          class="a"
          d="M376.172,260.148v-.117c-3.754-.506-6.62-3.088-6.62-6.187,0-3.2,3.061-5.853,7-6.231,1.3-3.877,5.729-6.608,10.922-6.608,4.485,0,8.5,2.076,10.306,5.248q.111,0,.22,0a6.574,6.574,0,0,1,5.673,2.843,6.656,6.656,0,0,1,1.466-.24c.765-4.323,5.515-7.677,11.192-7.677,5.134,0,9.574,2.713,10.9,6.552,3.575.646,6.164,3.152,6.164,6.115,0,3.1-2.866,5.681-6.62,6.187v.09l-1.349.027Z"
          transform="translate(-254.842 -185.369)"
        />
        <path
          class="a"
          d="M501.7,219.443a14.516,14.516,0,0,0-11.349,4.939,11.324,11.324,0,0,0-11.835-.109c0-.064.006-.128.006-.193,0-4.91-5.053-8.89-11.286-8.89-4.272,0-7.988,1.87-9.905,4.627a12.161,12.161,0,0,0-12.617-.006c-2.5-3.245-7.02-5.413-12.188-5.413-7.595,0-13.8,4.683-14.218,10.582a12.656,12.656,0,0,1,8.245,4.994,8.323,8.323,0,0,1,5.455,2.38c1.415-4.287,6.39-7.474,12.245-7.474,5.48,0,10.254,2.763,11.973,6.745,3.778.957,6.44,3.736,6.44,6.984a5.869,5.869,0,0,1-.343,1.958H501.7c7.405,0,13.408-4.728,13.408-10.562S509.108,219.443,501.7,219.443Z"
          transform="translate(-282.769 -170.133)"
        />
        <path
          class="a"
          d="M291.752,278.993a4.21,4.21,0,0,0-2.663.895,3.042,3.042,0,0,0-2.015-.714,3.3,3.3,0,0,0-1.16.207,4.6,4.6,0,0,0-2.794-1.506,4.216,4.216,0,0,0,.137-1.065c0-2.9-2.988-5.257-6.674-5.257a6.851,6.851,0,0,0-6.2,3.313,6,6,0,0,0-.956-.077c-2.259,0-4.146,1.254-4.6,2.926a5.784,5.784,0,0,0-1.905-.317,5.909,5.909,0,0,0-1.257.135c-.174-1.987-2.275-3.557-4.842-3.557a5.559,5.559,0,0,0-3.445,1.13,7.972,7.972,0,0,0-3.667-.866c-3.686,0-6.674,2.354-6.674,5.258s2.988,5.257,6.674,5.257l42.044.057c2.039,0,3.693-1.3,3.693-2.909S293.791,278.993,291.752,278.993Z"
          transform="translate(-182.379 -202.858)"
        />
        <path
          class="a"
          d="M451.19,127.189a8.375,8.375,0,0,0-5.293,1.778,7.33,7.33,0,0,0-5.92-2.689c-2.946,0-5.457,1.467-6.421,3.524a6.634,6.634,0,0,0-2.892-.644,6.348,6.348,0,0,0-3.828,1.217,6.346,6.346,0,0,0-3.827-1.217c-.174,0-.344.007-.514.02a4.659,4.659,0,0,0,.034-.55c0-3.195-3.288-5.784-7.343-5.784-3.95,0-7.17,2.456-7.336,5.536a6.633,6.633,0,0,0-2.869-.633,6.805,6.805,0,0,0-2.1.326l-.124-.061a5.643,5.643,0,0,0-5.152-2.789c-3.048,0-5.519,1.946-5.519,4.347a3.47,3.47,0,0,0,.037.5l-.094,0c-3.048,0-5.519,1.946-5.519,4.347,0,2.34,2.348,4.248,5.29,4.343v0H451.19c4.056,0,7.344-2.59,7.344-5.785S455.246,127.189,451.19,127.189Z"
          transform="translate(-264.557 -117.712)"
        />
        <path
          class="a"
          d="M110.193,293.222a5.118,5.118,0,0,0,.932-2.914c0-3.39-3.489-6.139-7.792-6.139a8.623,8.623,0,0,0-6.241,2.461,6.917,6.917,0,0,0-3.158-.74,5.925,5.925,0,0,0-5.395,2.909,5.375,5.375,0,0,0-3.417-1.155,4.883,4.883,0,0,0-4.341,2.256,5.1,5.1,0,0,0-3.522-1.578,7.375,7.375,0,0,0,.207-1.723c0-5.1-5.246-9.229-11.716-9.229S54.035,281.5,54.035,286.6c0,.043,0,.086,0,.128a9.882,9.882,0,0,0-1.558-.123c-4.3,0-7.793,2.748-7.793,6.138s3.488,6.139,7.793,6.139v.032h56.392c2.062,0,3.734-1.316,3.734-2.941A3.063,3.063,0,0,0,110.193,293.222Z"
          transform="translate(-68.776 -206.191)"
        />
        <path
          class="a"
          d="M213.19,142.677a7.185,7.185,0,0,0-3.341.8c-.347-2.358-2.89-4.189-5.978-4.189-2.953,0-5.408,1.676-5.919,3.886a6.458,6.458,0,0,0-5.192-2.344,6.869,6.869,0,0,0-4.334,1.452c-.728-3.184-4.261-5.6-8.512-5.6-4.37,0-7.982,2.552-8.567,5.868a6.994,6.994,0,0,0-3.984-1.188,7.327,7.327,0,0,0-2.741.521c-1.142-2.712-4.386-4.669-8.212-4.669-4.317,0-7.894,2.49-8.546,5.746a7.479,7.479,0,0,0-2.038-.279c-3.324,0-6.019,2.123-6.019,4.742,0,2.485,2.427,4.522,5.516,4.724v.018H213.19c3.324,0,6.019-2.123,6.019-4.742S216.514,142.677,213.19,142.677Z"
          transform="translate(-123.256 -125.635)"
        />
        <path
          class="a"
          d="M467.911,312.893a7.185,7.185,0,0,0-3.341.8c-.347-2.358-2.891-4.189-5.978-4.189-2.953,0-5.408,1.676-5.919,3.886a6.459,6.459,0,0,0-5.192-2.344,6.868,6.868,0,0,0-4.333,1.452c-.728-3.184-4.261-5.6-8.512-5.6-4.37,0-7.982,2.552-8.567,5.868a6.994,6.994,0,0,0-3.984-1.188,7.331,7.331,0,0,0-2.741.521c-1.142-2.712-4.386-4.669-8.212-4.669-4.316,0-7.894,2.49-8.545,5.746a7.479,7.479,0,0,0-2.038-.279c-3.325,0-6.019,2.122-6.019,4.741,0,2.485,2.427,4.522,5.516,4.724v.018h67.867c3.324,0,6.02-2.123,6.02-4.742S471.235,312.893,467.911,312.893Z"
          transform="translate(-269.147 -223.095)"
        />
      </g>
    </g>
    <g transform="translate(0 1)">
      <g
        class="e"
        transform="translate(76 54)"
      >
        <path
          class="g"
          d="M 63.81441879272461 59.00000762939453 L 5.185579776763916 59.00000762939453 C 4.463280200958252 59.00000762939453 3.816119909286499 58.62673568725586 3.454410076141357 58.00152587890625 C 3.092710018157959 57.37631607055664 3.091720104217529 56.62922668457031 3.451770067214966 56.00305557250977 L 32.76618957519531 5.021456241607666 C 33.1273307800293 4.393376350402832 33.77548980712891 4.018406391143799 34.5 4.018406391143799 C 35.22451019287109 4.018406391143799 35.8726692199707 4.393376350402832 36.23381042480469 5.021456241607666 L 65.54823303222656 56.00305557250977 C 65.90827941894531 56.62922668457031 65.90728759765625 57.37631607055664 65.54559326171875 58.00152587890625 C 65.18388366699219 58.62673568725586 64.53672027587891 59.00000762939453 63.81441879272461 59.00000762939453 Z"
        />
        <path
          class="h"
          d="M 34.5 5.018394470214844 C 34.3260498046875 5.018394470214844 33.89337158203125 5.067264556884766 33.63309097290039 5.519927978515625 L 4.318672180175781 56.50152587890625 C 4.059188842773438 56.95280456542969 4.233161926269531 57.35065460205078 4.319999694824219 57.50076675415039 C 4.406837463378906 57.6508674621582 4.665023803710938 57.99999618530273 5.185581207275391 57.99999618530273 L 63.81441879272461 57.99999618530273 C 64.33497619628906 57.99999618530273 64.59316253662109 57.6508674621582 64.68000030517578 57.50076675415039 C 64.76683807373047 57.35065460205078 64.94081115722656 56.95280456542969 64.68132781982422 56.50152587890625 L 35.36690902709961 5.519927978515625 C 35.10662841796875 5.067264556884766 34.6739501953125 5.018394470214844 34.5 5.018394470214844 M 34.5 3.018394470214844 C 35.51197814941406 3.018394470214844 36.52396011352539 3.519927978515625 37.1007194519043 4.522987365722656 L 66.41513824462891 55.50458526611328 C 67.56513214111328 57.50457763671875 66.12146759033203 59.99999618530273 63.81441879272461 59.99999618530273 L 5.185581207275391 59.99999618530273 C 2.878532409667969 59.99999618530273 1.434867858886719 57.50457763671875 2.584861755371094 55.50458526611328 L 31.8992805480957 4.522987365722656 C 32.47603988647461 3.519927978515625 33.48802185058594 3.018394470214844 34.5 3.018394470214844 Z"
        />
      </g>
      <path
        class="f"
        d="M1.37,5.429H-3.112L-4.049-12.85H2.307Zm-5.492,6.4A3.17,3.17,0,0,1-3.3,9.451a3.314,3.314,0,0,1,2.406-.8,3.22,3.22,0,0,1,2.36.823,3.162,3.162,0,0,1,.836,2.357,3.161,3.161,0,0,1-.845,2.329,3.164,3.164,0,0,1-2.351.851,3.257,3.257,0,0,1-2.388-.833A3.148,3.148,0,0,1-4.122,11.827Z"
        transform="translate(111.121 89.848)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ErrorHelperSvg'
}
</script>

<style scoped>
.a, .e {
  fill: #fff;
}

.b {
  fill: none;
}

.c {
  fill: #f6f6f6;
}

.d {
  clip-path: url(#a);
}

.f {
  fill: #ccc;
}

.g, .h {
  stroke: none;
}

.h {
  fill: #ccc;
}
</style>
