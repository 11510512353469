import Vue from 'vue'

// Mobile Saúde Vue UI
// Componentes para o Vue.js 2.x, integrado com o Element UI.
// https://www.npmjs.com/package/@mobilesaude/vue-element-ui
import MobileSaudeVueUI from '@mobilesaude/vue-element-ui'

// Vee Validate
// Simple Vue.js input validation plugin https://baianat.github.io/vee-validate/
// https://github.com/baianat/vee-validate
import VeeValidate from './plugins/vee-validate'

// Vue Moment
// Handy Moment.js filters for your Vue.js project.
// https://github.com/brockpetrie/vue-moment
import VueMoment from 'vue-moment'

// Vue Meta
import VueMeta from 'vue-meta'

// Mobile Saúde Vue UI CSS
import '@mobilesaude/vue-element-ui/dist/vue-element-ui.css'

// Global
import './assets/css/app.less'

import App from './App.vue'
import router from './Router'
import store from './Store'

// PWA
// import './registerServiceWorker'

// Vue inline svg
import InlineSvg from 'vue-inline-svg'
Vue.component('inline-svg', InlineSvg)

// Não avisar sobre o uso da versão dev do Vue em desenvolvimento.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.use(MobileSaudeVueUI, { env: process.env.NODE_ENV })
Vue.use(VeeValidate)
Vue.use(VueMoment, require('moment-timezone'))
Vue.use(VueMeta)

const VueApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default VueApp
