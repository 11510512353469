<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MosiaChat2',
  data () {
    return {
      funcionalidadeEmbed: null
    }
  },
  computed: {
    ...mapState('SetupApp', ['config']),
    ...mapState('Login', ['token']),
    ...mapGetters('SetupApp', [
      'aplicacao',
      'funcParams',
      'instanciaAplicacao',
      'bundle',
      'estilo',
      'idFuncionalidadeChatFlutuante'
    ]),
    tituloNavegacao () {
      return (this.funcionalidade || {}).nome
    },
    rotaChatMosia () {
      try {
        return this.aplicacao.rotas.chatBotClientWeb.rota
      } catch (ignored) {
        return ''
      }
    }
  },
  methods: {
    async init () {
      const embedConfig = {
        id: 'mosia-chat-2',
        url: this.rotaChatMosia,
        json: {
          configuration: {
            flutuante: true,
            bundle: this.bundle,
            idFuncionalidade: this.idFuncionalidadeChatFlutuante,
            instanciaAplicacao: this.instanciaAplicacao,
            versao: this.aplicacao.versao,
            plataforma: 'web_embed', // FIXME: 26/02/2024 martinazzi Entender o que seria essa plataforma web_embed
            authorization: this.token
          },
          aplicacaoConfig: this.config,
          colorScheme: this.estilo
        }
      }

      this.funcionalidadeEmbed = window.omnichannelSDK.embed(embedConfig)

      this.funcionalidadeEmbed.on('CREATED', () => {
      })

      this.funcionalidadeEmbed.on('LOADING', () => {
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<template>
  <div id="mosia-chat-2"/>
</template>

<style scoped>

</style>
