const listaDeFuncionalidadesInternas = [
  '3', '9', '134', '137', '138', '141', '49', '52'
]
const listaFuncionalidadesTipoChat = ['46']

const listaDeBaseRotasMSHub = [
  'msHub',
  'connectAtendente',
  'cms',
  'banners',
  'grupoFamiliar',
  'termoUso',
  'termosUsoConfirmarAceite',
  'termosUsoVerificarAceite',
  'termosUsoUltimoValido',
  'solicitacao',
  'detalheSolicitacao',
  'carregaBeneficiario'
]

const rotasDaAplicacao = {
  apiCMS: {
    cms: {
      'path': '',
      'urlBase': 'cms',
      'metodo': 'GET',
      'contentType': 'application/json'
    },
    banners: {
      'path': '',
      'urlBase': 'banners',
      'metodo': 'GET',
      'contentType': 'application/json'
    }
  },
  apiTermoDeUso: {
    getUltimoTermoDeUsoValido: {
      'path': '',
      'urlBase': 'termosUsoUltimoValido',
      'metodo': 'POST',
      'contentType': 'application/json'
    },
    termosUsoConfirmarAceite: {
      'path': '',
      'urlBase': 'termosUsoConfirmarAceite',
      'metodo': 'POST',
      'contentType': 'application/json'
    },
    termosUsoVerificarAceite: {
      'path': '',
      'urlBase': 'termosUsoVerificarAceite',
      'metodo': 'POST',
      'contentType': 'application/json'
    }
  },
  apiCarregaBeneficiario: {
    carrega: {
      'path': '',
      'urlBase': 'carregaBeneficiario',
      'metodo': 'POST',
      'contentType': 'application/json'
    }
  },
  apiGrupoFamiliar: {
    grupoFamiliar: {
      'path': '',
      'urlBase': 'grupoFamiliar',
      'metodo': 'POST',
      'contentType': 'application/json'
    }
  },
  apiSolicitacao: {
    solicitacao: {
      urlBase: 'solicitacao',
      path: '',
      metodo: 'GET'
    },
    enviarSolicitacao: {
      urlBase: 'solicitacao',
      path: '',
      metodo: 'POST'
    },
    detalheSolicitacao: {
      urlBase: 'detalheSolicitacao',
      path: '',
      metodo: 'GET'
    }
  },
  apiFormBuilder: {
    enviarFormBuilder: {
      urlBase: 'formBuilder',
      path: ':clientKey/form/:id/data',
      metodo: 'POST'
    }
  },
  apiRedirect: {
    redirect: {
      'path': '',
      'urlBase': 'funcionalidadeCustomizadaRedirect',
      'metodo': 'GET',
      'contentType': 'application/json'
    }
  },
  apiChavePasse: {
    criarChavePasse: {
      'path': '/criar',
      'urlBase': 'chavePasse',
      'metodo': 'POST',
      'contentType': 'application/json'
    },
    carregarDadosChavePasse: {
      'path': '/',
      'urlBase': 'chavePasse',
      'metodo': 'GET',
      'contentType': 'application/json'
    },
    atualizarChavePasse: {
      'path': '/atualizar',
      'urlBase': 'chavePasse',
      'metodo': 'PUT',
      'contentType': 'application/json'
    },
    carregarDadosSistemaPorChavePasse: {
      'path': '/sistema',
      'urlBase': 'chavePasse',
      'metodo': 'GET',
      'contentType': 'application/json'
    },
    carregarDadosUsuarioPorChavePasse: {
      'path': '/usuario',
      'urlBase': 'chavePasse',
      'metodo': 'GET',
      'contentType': 'application/json'
    },
    carregarDadosGrupoFamiliarUsuarioPorChavePasse: {
      'path': '/grupoFamiliar',
      'urlBase': 'chavePasse',
      'metodo': 'GET',
      'contentType': 'application/json'
    }
  },
  apiOcorrenciaPublico: {
    gerarOcorrencia: {
      'path': '/ocorrencia',
      'urlBase': 'public',
      'metodo': 'POST',
      'contentType': 'application/json'
    },
    carregarAnotacoes: {
      urlBase: 'public',
      path: '/ocorrencia/:idOperadora/:idOcorrencia/anotacoes',
      metodo: 'GET'
    }
  }
}

export {
  listaDeFuncionalidadesInternas,
  listaFuncionalidadesTipoChat,
  listaDeBaseRotasMSHub,
  rotasDaAplicacao
}
