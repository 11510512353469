// Initial state
const initialState = () => ({
  config: null,
  funcionalidade: null,
  bundle: '',
  bundleWeb: '',
  frameId: '',
  plataforma: false,
  apiAcesso: {},
  apiFuncionalidade: {},
  apiCMS: {},
  imagensWeb: null,
  esquemaDeCor: null,
  instanciaAplicacao: null
})

// root state object.
// each Vuex instance is just a single state tree.
const state = initialState

// getters are functions
const getters = {
  bundle: state => ((state.config || {}).aplicacao || {}).bundle,
  bundleWeb: state => state.bundleWeb,
  aplicacao: state => (state.config || {}).aplicacao,
  idFuncionalidadeChatFlutuante: state => ((state.config || {}).aplicacao || {}).idFuncionalidadeChatFlutuante,
  estilo: state => state.esquemaDeCor || {},
  mensageria: state => (state.config || {}).mensageria,
  liveNow: state => (state.config || {}).liveNow,
  dashBoard: state => (state.config || {}).dashBoard,
  tabBar: state => (state.config || {}).tabBar,
  imagens: state => state.imagensWeb,
  configuracaoAcesso: state => (state.config || {}).configuracaoAcesso,
  instanciaAplicacao: state => state.instanciaAplicacao || process.env.VUE_APP_INSTANCIA_APLICACAO_DEFAULT
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  initialState ({ commit }) {
    commit('INITIAL_STATE')
  }
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  SET_CONFIG (state, payload) {
    state.config = payload.config
    state.bundle = payload.bundle
    state.bundleWeb = payload.bundleWeb
    state.frameId = payload.frameId
    state.plataforma = payload.plataforma
    state.instanciaAplicacao = payload.instanciaAplicacao
  },
  SET_FUNCIONALIDADE (state, payload) {
    state.funcionalidade = payload
  },
  SET_FUNCIONALIDADES_ATIVAS (state, payload) {
    state.config.tabBar = payload.tabbarAtivas
    state.config.dashBoard = payload.dashboardAtivas
  },
  SET_API_FUNCIONALIDADE (state, payload) {
    state.apiFuncionalidade = payload
  },
  SET_API_ACESSO (state, payload) {
    state.apiAcesso = payload
  },
  INITIAL_STATE (state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  SET_API_CMS (state, payload) {
    state.apiCMS = payload
  },
  SET_ESQUEMA_DE_COR (state, payload) {
    if (!payload) {
      return
    }
    state.esquemaDeCor = payload
    state.imagensWeb = payload.imagensWeb
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
