import axios from 'axios'
import store from '@/Store'
import VueApp from '@/main.js'
import ConfiguradorApi from '@/api/ConfiguradorApi'
import PlataformaBeneficiarioApi from '@/api/PlataformaBeneficiarioApi'
import LogApi from '@/api/LogApi'

// Midleweres
const API = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API
})
API.interceptors.request.use((config) => {
  const token = store.state.Login.token
  const bundle = store.state.SetupApp.bundle

  if (token) {
    config.headers.Authorization = token
  }

  if (bundle) {
    config.headers.bundle = bundle
  }
  return config
}, (err) => Promise.reject(err))

API.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : 'Falha ao realizar requisição. Por favor tente novamente.'

  return {
    status: false,
    message,
    data: (error.response && error.response.data) ? error.response.data : null
  }
})

API.defaultOptions = {
  notifySuccess: false,
  notifyFailure: false
}

const mergeOptions = (options) => {
  return { ...API.defaultOptions, ...options }
}

API.notifyRequest = async (request, options) => {
  const opt = mergeOptions(options)
  const result = await request

  if (!result.status) {
    if (opt.notifyFailure) {
      const message = result.message ? result.message : 'Falha ao realizar requisiçao. Por favor tente novamente mais tarde'
      VueApp.$notify.error({
        title: 'Erro!',
        dangerouslyUseHTMLString: true,
        message: `${message} <br><small>${VueApp.$moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}</small>`,
        showClose: false,
        position: 'top-right',
        duration: 2000,
        customClass: `el-notification-error`
      })
    }

    return result
  } else {
    if (opt.notifySuccess) {
      VueApp.$notify.success({
        title: 'Sucesso!',
        dangerouslyUseHTMLString: true,
        message: `${result.message} <br><small>${VueApp.$moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}</small>`,
        showClose: false,
        position: 'top-right',
        duration: 2000,
        customClass: `el-notification-success`
      })
    }

    return result
  }
}

export const axiosAPI = API
export const ConfiguradorResource = ConfiguradorApi(API)
export const PlataformaBeneficiarioResource = PlataformaBeneficiarioApi(API)
export const LogResource = LogApi(API)
