import store from '@/Store'

export const slugGenerator = (text) => text.normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .replace(' ', '_')
  .replace(/[\W]/g, '')
  .toLowerCase()

export const encodeEmbedData = (string) => {
  let charCode = ''
  for (let letra of string) {
    charCode += (letra.charCodeAt(0) + 2).toString() + '.'
  }
  return btoa(charCode)
}

export const decodeEmbedData = (encodedString) => {
  // Convert Base64 to String and get charcode of chars
  const charCodeArr = atob(encodedString).split('.')
  let decodedData = ''
  charCodeArr.map((char) => {
    if (char !== '') {
      // convert charcode to String
      const character = String.fromCharCode((char - 2))
      decodedData += character
    }
  })
  return decodedData
}

export const permissoesTratadas = () => {
  let user = store.getters['Beneficiario/user'] || []
  let funcionalidadesFixas = funcionalidadesPadroes()
  let newPermissions = Array.isArray(user.permissoes) ? user.permissoes.filter((p) => !funcionalidadesFixas.includes(p.funcionalidade)) : []
  return newPermissions
}

export const funcionalidadesPadroes = () => {
  return ['1', '47777', '47888', '47999']
}
export const idFuncionalidadesOcultas = () => {
  let permissoes = permissoesTratadas()
  return permissoes.filter((perm) => perm.ocultar === true).map((perm) => perm.funcionalidade)
}

export const idFuncionalidadesSemAcesso = () => {
  let permissoes = permissoesTratadas()
  return permissoes.filter((perm) => perm.acesso === false).map((perm) => perm.funcionalidade)
}

export const isNotAccessGranted = (idFuncionalidade) => {
  let funcionalidadesSemAcesso = idFuncionalidadesSemAcesso()
  return funcionalidadesSemAcesso.includes(idFuncionalidade)
}
export const funcionalidadesArrayPermissao = () => {
  let funcionalidadesArrayPermissao = idFuncionalidadesSemAcesso()
  let funcionalidadesGruposSemAcesso = mapFuncionalidadesGruposSemAcesso()
  let funcionalidadesOcultas = idFuncionalidadesOcultas()
  funcionalidadesArrayPermissao = funcionalidadesArrayPermissao.concat(funcionalidadesGruposSemAcesso)
  funcionalidadesArrayPermissao = funcionalidadesArrayPermissao.concat(funcionalidadesOcultas)
  return funcionalidadesArrayPermissao
}

const mapFuncionalidadesGruposSemAcesso = () => {
  let dashboard = store.getters['SetupApp/dashBoard'] || []
  let grupos = dashboard.filter((p) => p.tipo === 'grupo')
  let permissoes = permissoesTratadas()
  let FuncionalidadesGrupos = []
  grupos.map((item) => {
    if (permissoes.find((perm) => perm.funcionalidade === item.id.toString())) {
      if (Object.prototype.hasOwnProperty.call(item, 'funcionalidades')) {
        item.funcionalidades.map((func) => FuncionalidadesGrupos.push(func.id))
      }
    }
  })
  return FuncionalidadesGrupos
}

export const allFuncionalidades = () => {
  let dashboard = store.getters['SetupApp/dashBoard'] || []
  let funcionalidades = dashboard.filter((p) => p.tipo === 'funcionalidade')
  let grupos = dashboard.filter((p) => p.tipo === 'grupo')
  let funcionalidadesGrupos = []
  grupos.map((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'funcionalidades')) {
      item.funcionalidades.map((func) => funcionalidadesGrupos.push(func))
    }
  })
  funcionalidades = funcionalidades.concat(funcionalidadesGrupos)
  funcionalidades = funcionalidades.concat(grupos)
  return funcionalidades
}

export const urlVerify = (url) => {
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://')
  } else if (!url.startsWith('https://')) {
    return 'https://' + url
  }
  return url
}
