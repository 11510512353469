<template>
  <svg
    height="150"
    viewBox="0 0 220 150"
    width="220"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
    </defs>
    <rect
      class="b"
      height="150"
      width="220"
    />
    <circle
      class="c"
      cx="60"
      cy="60"
      r="60"
      transform="translate(50 29)"
    />
    <g transform="translate(82 66.91)">
      <g transform="translate(0 5)">
        <path
          class="a"
          d="M10131.1-19217h23.8a6,6,0,0,1,3.159.9,6.007,6.007,0,0,1,2.211,2.43l8.624,17.223a1,1,0,0,1,.105.447v15a6.007,6.007,0,0,1-6,6h-40a6.007,6.007,0,0,1-6-6v-15a1,1,0,0,1,.105-.447l8.625-17.225a6.005,6.005,0,0,1,2.21-2.428A6,6,0,0,1,10131.1-19217Z"
          transform="translate(-10115 19222)"
        />
        <path
          class="d"
          d="M53,18H38l-5,7.5H23L18,18H3"
          transform="translate(0 8)"
        />
        <path
          class="d"
          d="M11.625,8.775,3,26V41a5,5,0,0,0,5,5H48a5,5,0,0,0,5-5V26L44.375,8.775A5,5,0,0,0,39.9,6H16.1a5,5,0,0,0-4.475,2.775Z"
        />
      </g>
      <g transform="translate(-1071.422 767.527) rotate(-45)">
        <line
          class="e"
          transform="translate(1310.689 221.5)"
          y1="10"
        />
        <line
          class="e"
          transform="translate(1326.639 228.551) rotate(45)"
          y1="10"
        />
        <line
          class="e"
          transform="translate(1333.689 243.5) rotate(90)"
          y1="10"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ErrorEmptySvg'
}
</script>

<style scoped>
.a {
  fill: #fff;
}

.b, .d, .e {
  fill: none;
}

.c {
  fill: #f6f6f6;
}

.d {
  stroke: #ccc;
  stroke-linejoin: round;
}

.d, .e {
  stroke-linecap: round;
  stroke-width: 2px;
}

.e {
  stroke: #ddd;
}
</style>
