import { Configurador } from '@mobilesaude/frontend_libs'
import store from '@/Store'

const ConfiguradorApi = () => {
  let configurador

  const getConfigurador = ({ bundle, bundleWeb, instanciaAplicacao }) => {
    if (configurador) {
      if (bundle) {
        configurador.bundle = bundle
      }
      if (bundleWeb) {
        configurador.bundleWeb = bundleWeb
      }

      if (instanciaAplicacao) {
        configurador.instanciaAplicacao = instanciaAplicacao
      }
      return configurador
    }

    configurador = new Configurador({
      bundle,
      bundleWeb,
      env: process.env.NODE_ENV,
      instanciaAplicacao: instanciaAplicacao || store.getters['SetupApp/instanciaAplicacao']
    })

    return configurador
  }

  return {
    async validaOrigemPlataforma ({ bundle, bundleWeb, instanciaAplicacao }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).validateOrigemPlataforma()
    },
    async validaOrigemFuncionalidade ({ bundle, bundleWeb, idFuncionalidade, instanciaAplicacao }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).validateOrigemFuncionalidade(idFuncionalidade)
    },
    async getEsquemaCor ({ bundle, bundleWeb, esquemaCor, instanciaAplicacao }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).getEsquemaCor(esquemaCor)
    },
    async getSetupApp ({ bundle, bundleWeb, instanciaAplicacao, canalAtendimento }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).getConfig(canalAtendimento)
    },
    async getSetupFuncionalidade ({ bundle, bundleWeb, idFuncionalidade, instanciaAplicacao }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).getFuncionalidade(idFuncionalidade)
    },
    async getTemplateSolicitacao ({ bundle, bundleWeb, idTemplate, instanciaAplicacao }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).getTemplateSolicitacao(idTemplate)
    },
    async getTemplatePesquisa ({ bundle, bundleWeb, pesquisaEnvioId, instanciaAplicacao }) {
      return getConfigurador({ bundle, bundleWeb, instanciaAplicacao }).getTemplatePesquisa(pesquisaEnvioId)
    }
  }
}
export default ConfiguradorApi
