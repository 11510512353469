<template>
  <div class="dashboard-screen">
    <div class="dashboard-sidebar">
      <SideMenu
        :selected="tabSelecionada"
      />
    </div>
    <div class="dashboard-container">
      <div class="header-line"></div>
      <div style="min-height: 100vh; background: #FFFFFF; max-width: 1100px; margin: 0 auto;">
      <termos-de-uso :idTermo="idTermo"></termos-de-uso>
      </div>
    </div>
  </div>
</template>
<script>
import TermosDeUso from '@/components/TermoDeUso/TermoDeUso'
import { slugGenerator } from '@/utils/utils'
import SideMenu from '@/views/Dashboard/SideMenu/SideMenu'
import { mapGetters, mapState } from 'vuex'
export default {
  components: { TermosDeUso, SideMenu },
  computed: {
    ...mapGetters('SetupApp', ['tabBar']),
    ...mapState('SetupApp', ['config']),
    idTermo () {
      return this.config.configuracaoAcesso.login.parametros.idTermosUso
    },
    paths () {
      return (String.prototype.split.call(this.$route.path, ['/']))
        .filter((p) => p !== '')
    },
    tabSelecionada () {
      if (this.paths[1] === 'inicio') {
        return this.tabBar[0]
      }

      return this.tabBar.find((v) => slugGenerator(v.nome) === this.paths[1])
    }
  }
}
</script>
