<template>
  <div
    v-if="banners.length > 0"
    class="banner"
  >
    <div v-html="estiloBotao"></div>
      <el-carousel :interval="5000" :autoplay="bannerMovimentoAutomatico" type="card" height="200px" trigger="click" ref="banners">
        <div class="banner-overlay-left" @click="$refs.banners.prev()"/>
        <div class="banner-overlay-right" @click="$refs.banners.next()"/>
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <el-image
            :src="item.url_imagem"
            @click="open(item)"
          />
        </el-carousel-item>
      </el-carousel>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { isNotAccessGranted } from '@/utils/utils'
import enumTipoDirecionamento from '@mobilesaude/frontend_libs/enums/enumTipoDirecionamento'
import { LogResource } from '../../API'
import enumContexto from '@mobilesaude/frontend_libs/enums/enumContexto'

export default {
  name: 'Banner',
  data () {
    return {
      banners: [],
      estiloBotao: ''
    }
  },
  computed: {
    ...mapGetters('SetupApp', ['estilo', 'dashBoard', 'aplicacao']),
    ...mapState('SetupApp', ['apiCMS', 'frameId']),
    ...mapGetters('Beneficiario', ['user']),
    bannerMovimentoAutomatico () {
      return this.aplicacao.bannerMovimentoAutomatico || false
    }
  },
  methods: {
    async init () {
      const result = await this.apiCMS.banners({
        loading: true
      })
      if (result.status) {
        this.banners = result.data
      }
      this.estiloBotao = `<style> .el-carousel__button {
          background: ${this.estilo.aplicacao.corPrimariaAplicacao} !important;
        } </style>`
    },
    open (banner) {
      let idFuncionalidade = banner.id_funcionalidade
      let idRegistro = banner.id_registro
      const tipoDirecionamento = banner.tipo_direcionamento

      if (!idRegistro && (idFuncionalidade === null || idFuncionalidade === undefined)) {
        this.$emit('show-dialog-permissao', {
          show: true,
          body: 'Funcionalidade indisponível para o usuário.'
        })
        return
      }
      if (idRegistro && tipoDirecionamento === enumTipoDirecionamento.LINKDAWEB) {
        this.registroAcesso(banner.id)
        return window.open(idRegistro, '_blank')
      }
      if (isNotAccessGranted(idFuncionalidade.toString())) {
        let permissao = this.user.permissoes.filter(permissao => permissao.funcionalidade === idFuncionalidade.toString()) || []
        let message = permissao.length > 0 && permissao[0].mensagemBloqueio ? permissao[0].mensagemBloqueio : 'Funcionalidade indisponível para o usuário.'

        this.$emit('show-dialog-permissao', {
          show: true,
          body: message
        })

        return
      }

      this.registroAcesso(banner.id)
      let data = {
        origin: 'omnichannel',
        client: 'func-handler',
        type: 'command',
        command: 'REDIRECT',
        data: {
          idFuncionalidade: banner.id_funcionalidade,
          idRegistro: banner.id_registro,
          user: this.user
        },
        timestamp: Date.now()
      }
      window.parent.postMessage(data, '*')
    },
    async registroAcesso (idRegistro) {
      await LogResource.registroAcesso(enumContexto.banner, idRegistro)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  max-height: 250px;
}

.el-carousel {
  text-align: center;
}

/deep/ .el-carousel__container {
  margin-bottom: -20px;
}

.banner-overlay-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 200px;
  z-index: 10;
  background: transparent linear-gradient(270deg, #FFFFFF00 0%, #FFFFFF 79%, #FFFFFF 100%) 0 0 no-repeat padding-box;
  cursor: pointer;
}

.banner-overlay-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  height: 200px;
  z-index: 10;
  background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 79%, #FFFFFF 100%) 0 0 no-repeat padding-box;
  cursor: pointer;
}

.el-image {
  max-width:  330px;
  border-radius: 10px;
}

.el-carousel__item.is-active > .el-image {
  max-width:  360px;
  border-radius: 10px;
}

/deep/ .el-image__inner {
  height: unset;
  max-height: 250px;
}

/deep/ .el-carousel__indicator--horizontal{
  padding: 0 4px;
}

/deep/ .el-carousel__button {
  height: 5px;
  width: 5px;
  border-radius: 100%;
}

/deep/ .el-carousel--horizontal {
  overflow-x: clip;
}
</style>
