<template>
  <div class="not-found">
    <h4>Página não encontrada.</h4>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
