import mshubPayload from '@/utils/mshubPayload'
import store from '@/Store'

const urlBase = process.env.VUE_APP_LOG_API
const LogApi = (API) => {
  return {
    async registroAcesso (idContexto, idRegistro) {
      const url = `${urlBase}/registroAcesso`
      return API.post(url, mshubPayload({ idContexto, idRegistro }), {
        headers: {
          instancia_aplicacao: store.getters['SetupApp/instanciaAplicacao']
        }
      })
    }
  }
}

export default LogApi
