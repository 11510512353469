<template>
  <ErrorLayout
    :btnTexto="btnTexto"
    :showBtn="showBtn"
    :svgNome="svgNome"
    :texto="texto"
    :titulo="titulo"
    @click="$emit('click')"
  />
</template>

<script>
import ErrorLayout from '@/components/Error/ErrorLayout'

export default {
  name: 'ErrorAlerta',
  components: { ErrorLayout },
  props: {
    svgNome: {
      type: String,
      default: 'ErrorHelperSvg'
    },
    titulo: {
      type: String,
      default: 'Erro no carregamento'
    },
    texto: {
      type: String,
      default: 'O sistema está passando por instabilidade e não pôde carregar os dados. Tente novamente ou fale com a Central de AtendimentoO sistema está passando por instabilidade e não pôde carregar os dados. Tente novamente ou fale com a Central de Atendimento.'
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    btnTexto: {
      type: String,
      default: 'Tentar novamente'
    }
  }
}
</script>

<style scoped>

</style>
