<template>
  <div class="error-screen">
    <div class="error-content">
      <div
        class="error-image"
        v-if="!url"
      >
        <ErrorCloudSvg
          v-if="svgNome === 'ErrorCloudSvg'"
        />
        <ErrorEmptySvg
          v-else-if="svgNome === 'ErrorEmptySvg'"
        />
        <ErrorWifiSvg
          v-else-if="svgNome === 'ErrorWifiSvg'"
        />
        <ErrorHelperSvg
          v-else
        />
      </div>
      <div
        class="error-image"
        v-else
      >
        <svg-icone
          :url="url"
        />
      </div>

      <div class="error-text">
        <h3 style="text-align: center">{{ titulo }}</h3>
      </div>

      <div class="error-text">
        <p style="text-align: center">{{ texto }}</p>
      </div>

      <div class="error-button">
        <el-link
            v-if="showBtn"
            :underline="false"
            :style="linkStyle"
            @click="$emit('click')"
        >
          {{ ('' + btnTexto).toUpperCase() }}
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorCloudSvg from '@/components/Error/ErrorSvg/ErrorCloudSvg'
import ErrorEmptySvg from '@/components/Error/ErrorSvg/ErrorEmptySvg'
import ErrorHelperSvg from '@/components/Error/ErrorSvg/ErrorHelperSvg'
import ErrorWifiSvg from '@/components/Error/ErrorSvg/ErrorWifiSvg'
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorLayout',
  components: { ErrorWifiSvg, ErrorHelperSvg, ErrorEmptySvg, ErrorCloudSvg },
  props: {
    svgNome: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    titulo: {
      type: String,
      default: 'Nenhum item cadastrado'
    },
    texto: {
      type: String,
      default: 'Ainda não foi cadastrado nenhum item para exibir.'
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    btnTexto: {
      type: String,
      default: 'Tentar novamente'
    }
  },
  computed: {
    ...mapGetters('SetupApp', ['estilo']),
    linkStyle () {
      return {
        color: `${this.estilo.aplicacao.corLinkAplicacao} !important`
      }
    }
  }
}
</script>

<style scoped>
.error-screen {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.error-content {
  width: 315px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.error-image {
}

.error-text {
}

.error-button {
  margin-top: 17px;
}
</style>
