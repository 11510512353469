<style>
.dashboard-screen {
  display: flex;
  flex-flow: row nowrap;
}

.dashboard-sidebar {
  width: 317px;
  min-height: 100vh;
  z-index: 1999;
}

.dashboard-sidebar-right {
  min-height: 100vh;
  width: 317px;
  z-index: 1999;
  background-color: #f6f6f6;
  background-image: url("https://omni-public-assets.s3.sa-east-1.amazonaws.com/portalBeneficiario/testing/comuns/sombra-interna-left.svg");
  background-position: left;
  background-repeat: repeat-y;
}

.dashboard-container {
  flex: 1;
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  height: fit-content;
}

.header-line {
  width: 100%;
  height: 1px;
  top: 80px;
  background: #eeeeee;
  position: absolute;
  right: 0;
  left: 0;
}
</style>
<template>
  <div class="dashboard-screen">
    <div class="dashboard-sidebar">
      <SideMenu
        :tabSelecionada="tabSelecionada"
        @show-dialog-permissao.capture="showDialogPermissao"
      />
    </div>
    <div class="dashboard-container">
      <div class="header-line" v-if="!isPesquisa || funcionalidade"></div>
      <div :style="styleContainer">
        <component
          :is="component"
          :key="$route.path"
          :funcionalidade="funcionalidade"
          :servicos="servicos"
          @show-dialog-permissao.capture="showDialogPermissao"
          @show-chat="exibeInstanciaMosiaChat"
        />
      </div>
    </div>
    <div class="dashboard-sidebar-right" v-if="!fullscreen"></div>
    <DialogDefault
      :open.sync="dialogPermissao"
      :title="dialogTitle"
      width="375px"
      top="20%"
      :body="dialogBody"
      @close="
        () => {
          dialogExpirado = false;
        }
      "
    />
    <ChatMosiaInstancia
      v-if="showMosiaChat"
      :idFuncionalidade="idFuncionalidade"
    />

    <mosia-chat2
      v-if="idFuncionalidadeChatFlutuante"
    />
  </div>
</template>

<script>
import { clienteLogo } from '@/utils/ImageBucket'
import SideMenu from '@/views/Dashboard/SideMenu/SideMenu'
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  slugGenerator,
  isNotAccessGranted,
  idFuncionalidadesOcultas
} from '@/utils/utils'
import TermoDeUso from '@/components/TermoDeUso/TermoDeUso'
import DialogDefault from '@/components/DialogDefault/DialogDefault'
import ChatMosiaInstancia from '@/components/ChatMosiaInstancia/index.vue'
import MosiaChat2 from '@/components/MosiaChat2/MosiaChat2.vue'

export default {
  name: 'Dashboard',
  components: {
    MosiaChat2,
    ChatMosiaInstancia,
    SideMenu,
    TermoDeUso,
    DialogDefault
  },
  data () {
    return {
      dialogTitle: '',
      dialogPermissao: false,
      dialogBody: '',
      showMosiaChat: false,
      idFuncionalidade: null,
      stylePesquisa: `height:100vh; background: #FFFFFF;`,
      styleDefault: `min-height: 100vh;background: #FFFFFF;max-width: 1100px;margin: 0 auto;height: fit-content`
    }
  },
  created () {
    this.exibeInstanciaMosiaChat({ show: true, idFuncionalidade: '46' })
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters('SetupApp', ['tabBar', 'dashBoard', 'configuracaoAcesso', 'idFuncionalidadeChatFlutuante']),
    ...mapGetters('Beneficiario', ['user']),
    ...mapState('SetupApp', ['apiCMS']),
    ...mapState('Login', ['token']),
    paths () {
      return String.prototype.split
        .call(this.$route.path, ['/'])
        .filter((p) => p !== '')
    },
    tabSelecionada () {
      if (this.paths[1] === 'inicio') {
        const tabsFilter = this.tabBar.filter((el) => el.id !== '47999')
        return tabsFilter[0]
      }

      return this.tabBar.find((v) => slugGenerator(v.nome) === this.paths[1])
    },
    funcionalidade () {
      const lastIndex = this.paths.length - 1

      if (lastIndex === 1) {
        return this.tabSelecionada
      }

      const path = decodeURI(this.paths[lastIndex])

      if (lastIndex > 2) {
        const pathGrupo = decodeURI(this.paths[2])

        const grupo = this.dashBoard.find(
          (func) => slugGenerator(func.nome) === pathGrupo
        )

        return grupo.funcionalidades.find(
          (f) => slugGenerator(f.nome) === path
        )
      }

      const todasFunc = this.tabBar.concat(this.dashBoard)

      return todasFunc.find((v) => slugGenerator(v.nome) === path)
    },
    isPesquisa () {
      return this.funcionalidade.id.toString() === '56'
    },
    styleContainer () {
      if (this.isPesquisa) {
        return this.stylePesquisa
      }
      return this.styleDefault
    },
    nomeComponente () {
      if (
        !this.funcionalidade ||
        this.funcionalidade.id.toString() === '47777'
      ) {
        return 'ListaServicos'
      }

      if (this.isPesquisa) {
        return 'Pesquisa'
      }

      if (this.funcionalidade.tipo === 'funcionalidade') {
        return 'FuncionalidadeHandler'
      }

      return 'ListaServicos'
    },
    fullscreen () {
      return this.nomeComponente !== 'ListaServicos'
    },
    servicos () {
      if (isNotAccessGranted(this.funcionalidade.id.toString())) {
        let permissao =
          this.user.permissoes.filter(
            (permissao) =>
              permissao.funcionalidade === this.funcionalidade.id.toString()
          ) || []
        if (permissao.length > 0) {
          this.showDialogPermissao({
            show: true,
            body:
              permissao[0].mensagemBloqueio ||
              'Funcionalidade indisponível para o usuário.',
            redirect: '/dashboard/servicos'
          })
        } else {
          this.showDialogPermissao({
            show: true,
            body: 'Funcionalidade indisponível para o usuário.',
            redirect: '/dashboard/servicos'
          })
        }
      }
      if (this.funcionalidade.tipo === 'grupo') {
        return this.funcionalidade.funcionalidades
      }

      return this.dashBoard.filter(
        (funcionalidade) => !funcionalidade.funcionalidade_interna
      )
    },
    component () {
      let component = ''
      if (this.nomeComponente === 'Pesquisa') {
        component = require(`@/views/${this.nomeComponente}/${this.nomeComponente}.vue`)
      } else {
        component = require(`./${this.nomeComponente}/${this.nomeComponente}.vue`)
      }

      return component.default
    },
    clienteLogo () {
      return clienteLogo('')
    }
  },
  methods: {
    ...mapMutations('SetupApp', ['SET_FUNCIONALIDADES_ATIVAS']),
    init () {
      if (isNotAccessGranted(this.funcionalidade.id.toString())) {
        let permissao =
          this.user.permissoes.filter(
            (permissao) =>
              permissao.funcionalidade === this.funcionalidade.id.toString()
          ) || []
        if (permissao.length > 0) {
          this.showDialogPermissao({
            show: true,
            body:
              permissao[0].mensagemBloqueio ||
              'Funcionalidade indisponível para o usuário.'
          })
        } else {
          this.showDialogPermissao({
            show: true,
            body: 'Funcionalidade indisponível para o usuário.'
          })
        }
      }
      this.verificarAceite()
      if (this.user.permissoes && this.user.permissoes.length) {
        this.setFuncionalidadesAtivas()
      }
    },
    async verificarAceite () {
      if (!this.token) {
        return
      }

      const idTermoUsoPlat =
        this.configuracaoAcesso.login.parametros.idTermosUso

      const res = await this.apiCMS.termosUsoVerificarAceite({
        payload: {
          id_termo_uso_plat: idTermoUsoPlat
        }
      })
      if (!res.data.aceito) {
        this.$router.push({ path: '/termosDeUso' })
      }
    },
    setFuncionalidadesAtivas () {
      let funcionalidades = idFuncionalidadesOcultas()
      funcionalidades.forEach(async (perm) => {
        if (perm.ocultar) {
          const tabbarAtivas = this.tabBar.filter(
            (item) => item.id !== perm.funcionalidade
          )
          const dashboardAtivas = this.dashBoard.filter(
            (item) => item.id !== perm.funcionalidade
          )
          await this.SET_FUNCIONALIDADES_ATIVAS({
            tabbarAtivas,
            dashboardAtivas
          })
        }
      })
    },
    showDialogPermissao (payload) {
      this.dialogBody = payload.body
      this.dialogTitle = payload.title || 'Atenção'
      this.dialogPermissao = payload.show
      if (payload.redirect) {
        this.$router.push({ path: payload.redirect })
      }
    },
    exibeInstanciaMosiaChat (payload) {
      this.idFuncionalidade = payload.idFuncionalidade
      this.showMosiaChat = payload.show
    }
  }
}
</script>
