import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  token: ''
})

const state = initialState

const getters = {
  getField,
  token: state => state.token
}

const actions = {}

const mutations = {
  updateField,
  INITIALIZE_LOGIN (state, payload) {
    state.token = payload.json.authorization
  },
  SET_TOKEN (state, payload) {
    state.token = payload
  },
  CLEAN_TOKEN (state) {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
