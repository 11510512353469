<template>
  <div class="container">
    <div class="grid-content bg-img area-beneficiario">
      <el-image
        v-if="bundle"
        class="background-image"
        :src="backgroundBanner"
        fit="cover"
      />
      <div class="card">
        <el-image
          style="height: 150px"
          :src="clienteLogo"
          fit="contain"
        />
        <p class="optional-text">
          {{this.aplicacao.textos.portalBeneficiarioAvisoAcessoMobile}}
        </p>
        <div class="download-buttons">
          <a :href="googlePlayLink" target="_blank">
            <img src="../../assets/img/img_download_android@2x.png" class="btn-loja" alt="Google Play" />
          </a>
          <a :href="appStoreLink" target="_blank">
            <img src="../../assets/img/img_download_ios@2x.png" class="btn-loja" alt="App Store" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MobileWarning',
  computed: {
    ...mapGetters('SetupApp', ['bundle', 'bundleWeb', 'aplicacao', 'tabBar', 'dashBoard', 'estilo', 'imagens', 'instanciaAplicacao']),
    backgroundBanner () {
      return this.imagens.backgroundWeb
    },
    clienteLogo () {
      return this.imagens.logoLoginWeb
    },
    googlePlayLink () {
      return `https://play.google.com/store/apps/details?id=${this.aplicacao.idAppLojaAndroid}`
    },
    appStoreLink () {
      return `https://apps.apple.com/br/app/${this.aplicacao.idAppLojaIos}`
    }
  },
  methods: {}
}
</script>

<style scoped>
.container {
  position: relative;
  height: 100vh;
}

.background-image {
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
}

.card {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 75%;
  max-width: 600px;
}

.optional-text {
  font-size: 16px;
  margin: 60px 0;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
  padding-bottom: 20px;
}

.btn-loja {
  max-width: 129px;
  max-height: 39px;
  cursor: pointer;
}
</style>
