// If using a module system (e.g. via vue-cli), import Vue and VueRouter
// and then call `Vue.use(VueRouter)`.
import Vue from 'vue'

// Vue Router
// The official router for Vue.js.
// https://github.com/vuejs/vue-router
import VueRouter from 'vue-router'

// Store
import store from '@/Store'

import NotFound from '@/views/pages/NotFound.vue'
import LoginRouter from '@/views/Login/LoginRouter'
import DashboardRouter from '@/views/Dashboard/DashboardRouter'
import TermoDeUso from '@/views/TermoDeUsoView/TermoDeUsoView'
import PesquisaRouter from './views/Pesquisa/PesquisaRouter'
import DescredenciadosRouter from './views/Descredenciados/DescredenciadosRouter'
import { isPublicRoute } from '@/utils/publicRoutes'

// Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.

const routes = [
  { path: '/termosDeUso', component: TermoDeUso },
  // Página não encontrada
  { path: '/404', component: NotFound, name: '404' },
  LoginRouter,
  DashboardRouter,
  PesquisaRouter,
  DescredenciadosRouter,
  // Qualquer rota desconhecida, redireciona para 404
  { path: '*', redirect: '/404' }
]

Vue.use(VueRouter)

// Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes // short for `routes: routes`
})

// Global Navigation Guards
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-guards
router.beforeEach(async (to, from, next) => {
  let token = store.getters['Login/token']
  if (token.length === 0) {
    if (to.path !== '/' && !isPublicRoute(to.path)) {
      next('/')
    }
  }
  next()
})

export default router
