<template>
  <div
    class="lista-servicos"
  >
    <el-row>
      <FuncionalidadeHeader
        :titulo="titulo"
        :voltar="showVoltar"
        :info="false"
      />
    </el-row>
    <el-row v-if="!showVoltar" style="margin: 0 20px">
      <Banner @show-dialog-permissao.passive="$emit('show-dialog-permissao', $event)" />
    </el-row>
    <el-row
      style="padding: 20px"
    >
      <template
        v-for="(item, index) in servicos"
      >
        <template v-if="!funcionalidadeOculta(item)">
          <el-col
            :key="index"
            :span="12"
            style="padding: 5px"
          >
            <FuncionalidadeBtn
              :funcionalidade="item"
              :index="index"
              @click="open(item)"
            />
          </el-col>
        </template>

      </template>
    </el-row>
  </div>
</template>

<script>
import { idFuncionalidadesOcultas, isNotAccessGranted, slugGenerator } from '@/utils/utils'
import FuncionalidadeBtn from '@/components/FuncionalidadeBtn/FuncionalidadeBtn'
import Banner from '@/components/Banner/Banner'
import FuncionalidadeHeader from '@/components/FuncionalidadeHeader/FuncionalidadeHeader'
import { mapGetters } from 'vuex'
import { listaFuncionalidadesTipoChat } from '@/utils/HandlerEnums'

export default {
  name: 'ListaServicos',
  components: { FuncionalidadeHeader, Banner, FuncionalidadeBtn },
  props: {
    servicos: {
      type: Array,
      default: () => []
    },
    funcionalidade: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
    }
  },
  metaInfo () {
    return {
      title: `${this.aplicacao.web.tituloPlataforma} - ${this.funcionalidade.nome}`
    }
  },
  computed: {
    ...mapGetters('SetupApp', ['aplicacao']),
    ...mapGetters('Beneficiario', ['user']),
    paths () {
      return (String.prototype.split.call(this.$route.path, ['/']))
        .filter((p) => p !== '')
    },
    titulo () {
      return this.showVoltar ? this.funcionalidade.nome : 'Serviços'
    },
    showVoltar () {
      return this.paths.length >= 3
    }
  },
  methods: {
    open (item) {
      let idFuncionalidade = item.id.toString()
      if (listaFuncionalidadesTipoChat.includes(idFuncionalidade)) {
        this.$emit('show-chat', {
          show: true,
          idFuncionalidade: idFuncionalidade
        })
        return
      }
      if (idFuncionalidade === null || idFuncionalidade === undefined) {
        this.$emit('show-dialog-permissao', {
          show: true,
          body: 'Funcionalidade indisponível para o usuário.'
        })
        return
      }
      if (isNotAccessGranted(idFuncionalidade)) {
        let permissao = this.user.permissoes.filter(permissao => permissao.funcionalidade === idFuncionalidade) || []
        if (permissao.length > 0) {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: permissao[0].mensagemBloqueio || 'Funcionalidade indisponível para o usuário.'
          })
        } else {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: 'Funcionalidade indisponível para o usuário.'
          })
        }
        return
      }
      const path = slugGenerator(item.nome)
      this.$router.push(`${this.$route.path}/${path}`)
    },
    funcionalidadeOculta (idFuncionalidade) {
      const funcionalidadesOcultas = [...idFuncionalidadesOcultas(), ...listaFuncionalidadesTipoChat]
      return funcionalidadesOcultas.includes(idFuncionalidade.id.toString())
    }
  }
}
</script>

<style scoped>
.lista-servicos {
  width: 100%;
}
</style>
