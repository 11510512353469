<template>
  <div style="height: 100vh;">
    <div
      id="func-handler"
      class="funcionalidade-handler"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { encodeEmbedData, slugGenerator, isNotAccessGranted } from '@/utils/utils'
import canal from '@/utils/canal'

export default {
  name: 'FuncionalidadeHandler',
  data () {
    return {
      titulo: '',
      funcionalidadeEmbed: null,
      dialogPermissao: false
    }
  },
  props: {
    funcionalidade: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState('Login', ['token']),
    ...mapState('SetupApp', ['apiAcesso']),
    ...mapState('Beneficiario', ['beneficiarioCompleto']),
    ...mapGetters('SetupApp', ['bundle', 'aplicacao', 'dashBoard', 'instanciaAplicacao']),
    ...mapGetters('Beneficiario', ['user']),
    tituloPagina () {
      if (this.titulo) {
        return `${this.aplicacao.web.tituloPlataforma} - ${this.titulo}`
      }
      return `${this.aplicacao.web.tituloPlataforma} - ${this.funcionalidade.nome}`
    }
  },
  mounted () {
    this.init()
  },
  metaInfo () {
    return {
      title: this.tituloPagina
    }
  },
  methods: {
    ...mapMutations('Beneficiario', ['INITIAL_STATE']),
    ...mapMutations('Login', ['CLEAN_TOKEN']),
    ...mapMutations('SetupApp', ['SET_ESQUEMA_DE_COR']),
    init: function () {
      const urlBase = process.env.NODE_ENV !== 'development' ? window.location.origin : ''
      const urlFuncionalidades = process.env.VUE_APP_FUNCIONALIDADES_FRONT

      let url = urlBase + urlFuncionalidades

      if (urlFuncionalidades.startsWith('http')) {
        url = urlFuncionalidades
      }

      if (isNotAccessGranted(this.funcionalidade.id.toString())) {
        let permissao = this.user.permissoes.filter(permissao => permissao.funcionalidade === this.funcionalidade.id.toString()) || []
        if (permissao.length > 0) {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: permissao[0].mensagemBloqueio || 'Funcionalidade indisponível para o usuário.',
            redirect: '/dashboard/servicos'
          })
        } else {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: 'Funcionalidade indisponível para o usuário.',
            redirect: '/dashboard/servicos'
          })
        }
        return
      }

      const loader = this.$loading({
        lock: true,
        fullscreen: true,
        customClass: 'myloading'
      })

      this.funcionalidadeEmbed = window.omnichannelSDK.embed({
        id: 'func-handler',
        url,
        json: {
          idFuncionalidade: this.funcionalidade.id,
          idRegistro: this.$route.query.idRegistro,
          authorization: this.token,
          beneficiario: this.beneficiarioCompleto,
          bundle: encodeEmbedData(this.bundle),
          plataforma: true,
          canal: canal,
          instanciaApicacao: this.instanciaApicacao,
          permiteGeolocalizacao: true,
          minHeight: '100vh'
        }
      })

      this.funcionalidadeEmbed.on('CREATED', (response) => {
        loader.close()
      })

      this.funcionalidadeEmbed.on('LOADING', (response) => {
      })

      this.funcionalidadeEmbed.on('LOGOUT', (response) => {
        this.logout(response)
      })

      this.funcionalidadeEmbed.on('TROCA_PAGINA', (response) => {
        if (Object.prototype.hasOwnProperty.call(response, 'idFuncionalidade')) {
          if (isNotAccessGranted(response.idFuncionalidade.toString())) {
            let permissao = this.user.permissoes.filter(permissao => permissao.funcionalidade === response.idFuncionalidade.toString()) || []
            if (permissao.length > 0) {
              this.$emit('show-dialog-permissao', {
                show: true,
                body: permissao[0].mensagemBloqueio || 'Funcionalidade indisponível para o usuário.',
                redirect: '/dashboard/servicos'
              })
            } else {
              this.$emit('show-dialog-permissao', {
                show: true,
                body: 'Funcionalidade indisponível para o usuário.',
                redirect: '/dashboard/servicos'
              })
            }
            return
          }
        }

        this.titulo = response.titulo
      })

      this.funcionalidadeEmbed.on('REDIRECT', (response) => {
        let vm = this
        if (isNotAccessGranted(response.idFuncionalidade.toString())) {
          if (vm.user.permissoes && vm.user.permissoes.length > 0) {
            let permissao = vm.user.permissoes.filter(permissao => permissao.funcionalidade === response.idFuncionalidade.toString()) || []
            if (permissao.length > 0) {
              this.$emit('show-dialog-permissao', {
                show: true,
                body: permissao[0].mensagemBloqueio || 'Funcionalidade indisponível para o usuário.',
                redirect: '/dashboard/servicos'
              })
            } else {
              this.$emit('show-dialog-permissao', {
                show: true,
                body: 'Funcionalidade indisponível para o usuário.',
                redirect: '/dashboard/servicos'
              })
            }
            return
          }
        }
        let path = '/dashboard/servicos'
        let funcionalidadeValida = true
        let funcionalidadeExiste = false
        this.dashBoard.forEach((item) => {
          if (item.tipo.toString() === 'funcionalidade') {
            if (item.id.toString() === response.idFuncionalidade.toString()) {
              funcionalidadeExiste = true
              if (vm.user.permissoes && vm.user.permissoes.length > 0) {
                const funcionalidadesNaoPermitidas = vm.user.permissoes.filter((permissao) => item.id === permissao.funcionalidade)
                if (funcionalidadesNaoPermitidas && funcionalidadesNaoPermitidas.length > 0) {
                  funcionalidadeValida = (!funcionalidadesNaoPermitidas.acesso || !funcionalidadesNaoPermitidas.ocultar)
                }
              } else {
                funcionalidadeValida = true
              }
              path += `/${slugGenerator(item.nome)}`
            }
          }
          if (item.tipo.toString() === 'grupo') {
            if (Object.prototype.hasOwnProperty.call(item, 'funcionalidades')) {
              item.funcionalidades.forEach((itemGrupo) => {
                if (itemGrupo.id.toString() === response.idFuncionalidade.toString()) {
                  funcionalidadeExiste = true
                  if (vm.user.permissoes && vm.user.permissoes.length > 0) {
                    const funcionalidadesNaoPermitidas = vm.user.permissoes.filter((permissao) => itemGrupo.id === permissao.funcionalidade)
                    if (funcionalidadesNaoPermitidas && funcionalidadesNaoPermitidas.length > 0) {
                      funcionalidadeValida = (!funcionalidadesNaoPermitidas.acesso || !funcionalidadesNaoPermitidas.ocultar)
                    }
                  } else {
                    funcionalidadeValida = true
                  }
                  path += `/${slugGenerator(item.nome)}/${slugGenerator(itemGrupo.nome)}`
                }
              })
            }
          }
        })
        const query = {}
        if (response.idRegistro) { // se tiver o idRegistro, passa
          query.idRegistro = response.idRegistro
        }
        if (funcionalidadeExiste && funcionalidadeValida) {
          this.$router.push({
            path,
            query
          })
        } else {
          this.$emit('show-dialog-permissao', {
            show: true,
            body: 'Funcionalidade indisponível.'
          })
        }
      })

      this.funcionalidadeEmbed.on('VOLTAR_DASHBOARD', () => {
        this.$router.push('/dashboard/servicos')
      })
    },
    async logout (data) {
      await this.apiAcesso.login.logout()
      this.INITIAL_STATE()
      this.CLEAN_TOKEN()
      this.SET_ESQUEMA_DE_COR(null)

      let path = `/`

      if (data.expirado) {
        path = '/?expirado=true'
      }

      this.$router.push({ path })
    }
  }
}
</script>

<style scoped>
.funcionalidade-handler {
  width: 100%;
  height: 100%;
}
</style>
