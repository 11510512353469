<style scoped>
.funcionalidade-header {
  padding: 20px;
  margin-bottom: 20px;
  height: 40px;
}

.funcionalidade-header-float {
  box-shadow: 0 1px 0 #eeeeee;
  background: #FFFFFF 0 0 no-repeat padding-box;
  padding: 20px;
  height: 40px;
  position: fixed;
  top: 0;
  left: 317px;
  right: 317px;
  z-index: 10;
}

.funcionalidade-header-content {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}
</style>
<template>
  <div class="funcionalidade-header">
    <div
      class="funcionalidade-header-float"
    >
      <div class="funcionalidade-header-content">
        <div style="flex: 1;">
          <h1 :style="estiloTituloNavegacao">{{ tituloNavegacao }}</h1>
        </div>
        <el-button
          v-if="voltar"
          :style="estiloVoltarNavegacao"
          type="text"
          @click="clickVoltar"
        >
          <i class="el-icon-back"/> Voltar
        </el-button>
        <!-- TODO: 28/09/2022 martinazzi desativando até segunda ordem -->
        <el-button
          v-if="info && false"
          :style="estiloIconeNavegacao"
          type="text"
        >
          <i class="el-icon-warning-outline"/>
        </el-button>
      </div>

      <!--      <el-col
              v-if="menuFuncionalidades.length > 0"
              :lg="24"
              :md="24"
              :sm="24"
              :xl="24"
              :xs="24"
            >
              <div
                style="height: 75px;width: 60px; background: rgba(0,0,0,0.3);"
                v-for="(f, index) in menuFuncionalidades"
                :key="index"
              >
                {{ f.nome }}
              </div>
            </el-col>-->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FuncionalidadeHeader',
  props: {
    voltar: {
      type: Boolean,
      default: true
    },
    info: {
      type: Boolean,
      default: true
    },
    customVoltar: {
      type: Boolean,
      default: false
    },
    titulo: {
      type: String,
      default: ''
    },
    menuFuncionalidades: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('SetupApp', ['funcionalidade', 'frameId']),
    ...mapGetters('SetupApp', ['estilo']),
    funcData () {
      return this.funcionalidade || {}
    },
    funcParametros () {
      return this.funcData.parametros || {}
    },
    tituloNavegacao () {
      return this.titulo
    },
    estiloTituloNavegacao () {
      return {
        color: this.funcParametros.corTituloNavegacao
      }
    },
    estiloIconeNavegacao () {
      return {
        color: this.funcParametros.corIconeNavegacao
      }
    },
    estiloVoltarNavegacao () {
      return {
        color: this.funcParametros.corVoltarNavegacao
      }
    }
  },
  methods: {
    clickVoltar () {
      if (this.customVoltar) {
        return this.$emit('click')
      }
      window.history.go(-1)
    }
  }
}
</script>
