<template>
  <div class="browser-warning">
    <div class="warning-icon">
      <ErrorHelperSvg
      />
    </div>
    <h2 class="browser-warning-title">{{ this.aplicacao.textos.tituloBrowserIncompativelBeneficiarioWeb }}</h2>
    <p class="browser-warning-text">
      {{ this.aplicacao.textos.textoBrowserIncompativelBeneficiarioWeb }}
    </p>
    <hr class="divisao-hr">
    <div class="browser-info">
      <span class="browser-info-title"><strong>Navegador atual</strong></span>
      <div class="current-browser">
        <div class="browser-details">
          <span class="texto"
            >{{ navegador.browser.name }} - {{ navegador.browser.version }}</span
          >
          <span class="incompatible"
            >INCOMPATÍVEL
            <i class="el-icon-circle-close"></i>
          </span>
        </div>
      </div>
      <span class="browser-info-title"
        ><strong>Nossa recomendação</strong></span
      >
      <div class="recommended-browsers">
        <div
          class="browser-recommendation"
          v-for="browser in compatibleBrowsers"
          :key="browser.name"
        >
          <span class=" texto">{{ browser.name }}</span>
          <el-button
            class="recommended"
            type="text"
            @click="openBrowserUrl(browser.url)"
          >
            DOWNLOAD
            <i class="el-icon-download"></i>
          </el-button>
        </div>
      </div>
    </div>
    <span class="download-apps"
    ><strong>Instale nosso aplicativo gratuito:</strong></span>
    <div class="download-buttons">
      <a :href="appStoreLink" target="_blank">
        <img src="../../assets/img/img_download_ios@2x.png" class="btn-loja" alt="App Store" />
      </a>
      <a :href="googlePlayLink" target="_blank">
        <img src="../../assets/img/img_download_android@2x.png" class="btn-loja" alt="Google Play" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ErrorHelperSvg from '@/components/Error/ErrorSvg/ErrorHelperSvg.vue'

export default {
  name: 'BrowserWarning',
  components: { ErrorHelperSvg },
  props: {
    navegador: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      compatibleBrowsers: [
        { name: 'Google Chrome', url: 'https://www.google.com/chrome/' },
        { name: 'Microsoft Edge', url: 'https://www.microsoft.com/edge' }
      ]
    }
  },
  computed: {
    ...mapGetters('SetupApp', ['aplicacao', 'aplicacao']),
    ...mapState('SetupApp', ['esquemaDeCor']),
    googlePlayLink () {
      return `https://play.google.com/store/apps/details?id=${this.aplicacao.idAppLojaAndroid}`
    },
    appStoreLink () {
      return `https://apps.apple.com/br/app/${this.aplicacao.idAppLojaIos}`
    }
  },
  methods: {
    openBrowserUrl (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
.browser-warning {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: OpenSans, sans-serif;
  text-align: center;
}
.divisao-hr {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 20px 0;
}

.warning-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  margin: 0 auto 20px auto;
  background: var(--cor-primaria-aplicacao-transparent);
  border-radius: 100%;
  padding: 16px;
}

.browser-warning-title {
  font-size: 26px;
  color: #333;
  margin-bottom: 10px;
}

.browser-warning-text {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.browser-info {
  margin-top: 20px;
}

.browser-info-title {
  display: block;
  text-align: start;
  padding-bottom: 15px;
  font-size: 14px;
}
.download-apps {
  display: block;
  text-align: center;
  margin: 15px 0;
  font-size: 14px;
}

.current-browser {
  margin-bottom: 20px;
}

.browser-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffe6e6;
  border-radius: 10px;
  height: 49px;
  text-align: center;
}

.browser-details .texto {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.incompatible {
  margin-right: 15px;
  color: #ff0000;
  font-size: 11px;
  font-weight: bold;
}

.recommended-browsers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.browser-recommendation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f8ff;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 49px;
  width: 100%;
}

.browser-recommendation .texto {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.recommended {
  margin-right: 15px;
  font-size: 11px;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-bottom: 20px;
}

.btn-loja {
  max-height: 50px;
  cursor: pointer;
}
</style>
